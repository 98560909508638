import { getRequest, postRequest } from './index'
import { ICFSAPI } from '@/config'

// 查询产融超市这边的字典
export const selectAllDict = (successCallback, failureCallback) => {
  getRequest(`${ICFSAPI}loan/sys/data/dictionary/selectAllDict`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 查询产融超市这边的省市
export const selectAreaTree = (areaType, successCallback, failureCallback) => {
  getRequest(`${ICFSAPI}loan/sys/data/dictionary/dataDict/selectAreaTree?areaType=` + areaType, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 查询产品 带分页
export const listPage = (data, successCallback, failureCallback) => {
  postRequest(`${ICFSAPI}app/home/listPage`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 查询产品详情
export const getProductDesc = (productId, successCallback, failureCallback) => {
  getRequest(`${ICFSAPI}app/product/center/getProductDesc?productId=${productId}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
